import React from 'react';
import { Link } from 'react-router-dom';

function BlogSingle() {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">

            <article>

              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h1 mb-4" data-aos="fade-up">E&S 와 함께 하실 인재를 채용합니다.</h1>
                  <p className="text-xl text-gray-400" data-aos="fade-up" data-aos-delay="200">안녕하세요. 저희 회사와 함께 성장하실 인재를 채용하고 있습니다. 평소에 마케팅에 관심이 많거나 영업에 소질이 있으신 분들은 언제든지 이력서를 제출해 주시기 바랍니다.</p>
                  <p className="text-xl text-gray-400 mt-3" data-aos="fade-up" data-aos-delay="200">저희 회사는 자유로운 분위기 입니다. 개인의 역량을 최대한 존중해 드리며 아낌없는 지원을 하고 있습니다. 다양한 경험을 해보고 싶으신 분들과 도전적인 마음가짐을 가진 분이라면 언제든 저희 E&S마케팅에 지원해 주시길 바랍니다.</p>
                  <p className="text-xl text-gray-400 mt-3" data-aos="fade-up" data-aos-delay="200">감사합니다.</p>
                </div>
                
              </header>

              {/* Article image */}
              <figure className="mb-8 lg:-ml-32 lg:-mr-32" data-aos="fade-up" data-aos-delay="600">
                <div className=" justify-center items-center">
                  <img className="w-full" src={require('../images/blog-post-02.jpg')}  alt="News single" />
                </div>

              </figure>

              {/* Article content */}
              <div className="text-lg text-gray-400">
                
                <h3 className="h3 mb-4 text-gray-200">모집분야</h3>                
                <ul className="list-disc list-inside mb-8">
                  <li>마케팅 광고 홍보</li>
                  <li>교육 컨설팅</li>
                  <li>담당업무 : 바이럴, 온라인마케팅, 교육 컨설턴트</li>
                </ul>

                <h3 className="h3 mb-4 text-gray-200">급여조건</h3>                
                <ul className="list-disc list-inside mb-8">
                  <li>신입 : 기본급 + 성과급 </li>
                  <li>경력자는 면접후 결정</li>
                </ul>

                <h3 className="h3 mb-4 text-gray-200">근무조건</h3>                
                <ul className="list-disc list-inside mb-8">
                  <li>6개월 이상 근무</li>
                  <li>요일 : 월~금 </li>
                  <li>근무시간 : 10:00 ~ 19:00 / 휴게시간 : 60분</li>
                  <li>복리후생 : 정기휴가,인센티브제,정기보너스,우수사원 표창/포상</li>
                  <li>4대보험</li>
                </ul>

                <h3 className="h3 mb-4 text-gray-200">지원조건</h3>                
                <ul className="list-disc list-inside mb-8">
                  <li>성별 : 성별무관</li>
                  <li>연령 : 20세 이상 ~ 30세 이하</li>
                  <li>학력 : 고등학교 졸업</li>
                  <li>우대조건 : 문과생, 디자이너, 영업 경험, 마케팅 경험, 디자인 경험, 유사업무 경험, 군필자 </li>
                </ul>

                <h3 className="h3 mb-4 text-gray-200">접수방법</h3>                
                <ul className="list-disc list-inside mb-8">
                  <li>이메일 : qna@eandsmarketing.com 으로 자유 이력서 제출해주세요.</li>
                </ul>


              </div>
                
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogSingle;