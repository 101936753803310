import React from "react";

function Process() {
    return (
        <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6 border-t border-gray-800">
                <div className="pt-10 pb-12 md:pt-16 md:pb-20">
                    {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                        <h2 className="h2 mb-4" data-aos="fade-up">
                            PROCEDURE
                        </h2>
                        <p className="text-xl text-gray-400" data-aos="fade-up" data-aos-delay="200">
                            체계적인 9가지 단계로 절차지향적인 시스템을 지향하고 있습니다.
                        </p>
                    </div>

                    {/* Items */}
                    <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:gap-16 items-start md:max-w-none">
                        {/* 1st item */}
                        <div className="relative flex flex-col items-center" data-aos="fade-up">
                            <div aria-hidden="true" className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block" style={{ width: "calc(100% - 32px)", left: "calc(50% + 48px)", top: "32px" }} data-aos="fade-in" data-aos-delay="200"></div>
                            <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                                <path className="stroke-current text-purple-300" strokeWidth="2" strokeLinecap="square" d="M21 23h22v18H21z" fill="none" fillRule="evenodd" />
                                <path className="stroke-current text-purple-100" d="M26 28h12M26 32h12M26 36h5" strokeWidth="2" strokeLinecap="square" />
                            </svg>
                            <h4 className="h4 mb-2">
                                <span className="text-gray-400">1</span>. Consulting
                            </h4>
                            <p className="text-lg text-gray-400 text-center">전문 상담사와 연결되어 어떠한 프로젝트인지 세세히 분석하여 프로젝트에 맞는 컨설팅이 무료로 진행됩니다.</p>
                        </div>

                        {/* 2nd item */}
                        <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="200">
                            <div aria-hidden="true" className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block" style={{ width: "calc(100% - 32px)", left: "calc(50% + 48px)", top: "32px" }} data-aos="fade-in" data-aos-delay="400"></div>
                            <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                                <g fill="none" fillRule="evenodd">
                                    <path className="stroke-current text-purple-300" d="M40 22a2 2 0 012 2v16a2 2 0 01-2 2H24a2 2 0 01-2-2V24a2 2 0 012-2" strokeWidth="2" strokeLinecap="square" />
                                    <path className="stroke-current text-purple-100" strokeWidth="2" strokeLinecap="square" d="M36 32l-4-3-4 3V22h8z" />
                                </g>
                            </svg>
                            <h4 className="h4 mb-2">
                                <span className="text-gray-400">2</span>. Select Type
                            </h4>
                            <p className="text-lg text-gray-400 text-center">프로젝트의 특성에 맞게 다양한 마케팅 방법들을 선별하여 파트너사의 브랜드와 가장 적합한 유형을 제안합니다. </p>
                        </div>

                        {/* 3rd item */}
                        <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="400">
                            <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                                <path className="stroke-current text-purple-300" strokeWidth="2" strokeLinecap="square" d="M21 35l4 4 12-15" fill="none" fillRule="evenodd" />
                                <path className="stroke-current text-purple-100" d="M42 29h-3M42 34h-7M42 39H31" strokeWidth="2" strokeLinecap="square" />
                            </svg>
                            <h4 className="h4 mb-2">
                                <span className="text-gray-400">3</span>. Idea Processing
                            </h4>
                            <p className="text-lg text-gray-400 text-center">선택된 유형을 기반으로 전문 마케터들과 아이디어 회의를 통해 기획안이 작성됩니다. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pt-10 pb-12 md:pt-16 md:pb-20">
                    {/* Items */}
                    <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:gap-16 items-start md:max-w-none">
                        {/* 1st item */}
                        <div className="relative flex flex-col items-center" data-aos="fade-up">
                            <div aria-hidden="true" className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block" style={{ width: "calc(100% - 32px)", left: "calc(50% + 48px)", top: "32px" }} data-aos="fade-in" data-aos-delay="200"></div>
                            <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                                <path className="stroke-current text-purple-300" strokeWidth="2" strokeLinecap="square" d="M21 23h22v18H21z" fill="none" fillRule="evenodd" />
                                <path className="stroke-current text-purple-100" d="M26 28h12M26 32h12M26 36h5" strokeWidth="2" strokeLinecap="square" />
                            </svg>
                            <h4 className="h4 mb-2">
                                <span className="text-gray-400">4</span>. Creating
                            </h4>
                            <p className="text-lg text-gray-400 text-center">완성된 기획안에 필요한 자료를 조사, 수집, 제작하여 시뮬레이션을 통한 검수가 진행됩니다.</p>
                        </div>

                        {/* 2nd item */}
                        <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="200">
                            <div aria-hidden="true" className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block" style={{ width: "calc(100% - 32px)", left: "calc(50% + 48px)", top: "32px" }} data-aos="fade-in" data-aos-delay="400"></div>
                            <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                                <g fill="none" fillRule="evenodd">
                                    <path className="stroke-current text-purple-300" d="M40 22a2 2 0 012 2v16a2 2 0 01-2 2H24a2 2 0 01-2-2V24a2 2 0 012-2" strokeWidth="2" strokeLinecap="square" />
                                    <path className="stroke-current text-purple-100" strokeWidth="2" strokeLinecap="square" d="M36 32l-4-3-4 3V22h8z" />
                                </g>
                            </svg>
                            <h4 className="h4 mb-2">
                                <span className="text-gray-400">5</span>. Operating
                            </h4>
                            <p className="text-lg text-gray-400 text-center">프로젝트 마케팅에 필요한 운영 노하우들을 여러 협력사들과 취합하여 제공합니다.</p>
                        </div>

                        {/* 3rd item */}
                        <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="400">
                            <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                                <path className="stroke-current text-purple-300" strokeWidth="2" strokeLinecap="square" d="M21 35l4 4 12-15" fill="none" fillRule="evenodd" />
                                <path className="stroke-current text-purple-100" d="M42 29h-3M42 34h-7M42 39H31" strokeWidth="2" strokeLinecap="square" />
                            </svg>
                            <h4 className="h4 mb-2">
                                <span className="text-gray-400">6</span>. Marketing
                            </h4>
                            <p className="text-lg text-gray-400 text-center">효과적인 홍보 전략을 통해 상품의 가치를 더욱 극대화 시키는 마케팅이 실행됩니다.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pt-10 pb-12 md:pt-16 md:pb-20">
                    {/* Items */}
                    <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:gap-16 items-start md:max-w-none">
                        {/* 1st item */}
                        <div className="relative flex flex-col items-center" data-aos="fade-up">
                            <div aria-hidden="true" className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block" style={{ width: "calc(100% - 32px)", left: "calc(50% + 48px)", top: "32px" }} data-aos="fade-in" data-aos-delay="200"></div>
                            <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                                <path className="stroke-current text-purple-300" strokeWidth="2" strokeLinecap="square" d="M21 23h22v18H21z" fill="none" fillRule="evenodd" />
                                <path className="stroke-current text-purple-100" d="M26 28h12M26 32h12M26 36h5" strokeWidth="2" strokeLinecap="square" />
                            </svg>
                            <h4 className="h4 mb-2">
                                <span className="text-gray-400">7</span>. Managing
                            </h4>
                            <p className="text-lg text-gray-400 text-center">프로젝트 과정속에서 발생될 수 있는 변수들을 대비하여 지속적인 모니터링이 진행됩니다.</p>
                        </div>

                        {/* 2nd item */}
                        <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="200">
                            <div aria-hidden="true" className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block" style={{ width: "calc(100% - 32px)", left: "calc(50% + 48px)", top: "32px" }} data-aos="fade-in" data-aos-delay="400"></div>
                            <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                                <g fill="none" fillRule="evenodd">
                                    <path className="stroke-current text-purple-300" d="M40 22a2 2 0 012 2v16a2 2 0 01-2 2H24a2 2 0 01-2-2V24a2 2 0 012-2" strokeWidth="2" strokeLinecap="square" />
                                    <path className="stroke-current text-purple-100" strokeWidth="2" strokeLinecap="square" d="M36 32l-4-3-4 3V22h8z" />
                                </g>
                            </svg>
                            <h4 className="h4 mb-2">
                                <span className="text-gray-400">8</span>. Reporting
                            </h4>
                            <p className="text-lg text-gray-400 text-center">완성된 프로젝트의 결과값을 파트너사에 제출 후 피드백을 통해 필요한 부분은 수정 및 보완합니다.</p>
                        </div>

                        {/* 3rd item */}
                        <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="400">
                            <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                                <path className="stroke-current text-purple-300" strokeWidth="2" strokeLinecap="square" d="M21 35l4 4 12-15" fill="none" fillRule="evenodd" />
                                <path className="stroke-current text-purple-100" d="M42 29h-3M42 34h-7M42 39H31" strokeWidth="2" strokeLinecap="square" />
                            </svg>
                            <h4 className="h4 mb-2">
                                <span className="text-gray-400">9</span>. Result
                            </h4>
                            <p className="text-lg text-gray-400 text-center">만족스러운 결과를 토대로 다음 프로젝트 상품의 마케팅을 준비합니다.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Process;
