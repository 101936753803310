import React from "react";

function Target() {
    return (
        <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="py-12 md:py-20 border-t border-gray-800">
                    {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                        <h2 className="h2" data-aos="fade-up">
                            OUR RESULTS
                        </h2>
                    </div>

                    {/* Items */}
                    <div className="grid gap-20" data-aos-id-target>
                        {/* Item */}
                        <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                            {/* Image */}
                            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl" data-aos="fade-right" data-aos-delay="200" data-aos-anchor="[data-aos-id-target]">
                                <img className="mx-auto md:max-w-none" src={require("../images/features-02-image.png")} width="540" height="520" alt="Features 02" />
                            </div>

                            {/* Content */}
                            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6">
                                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                                    <div className="font-architects-daughter text-xl text-purple-600 mb-2" data-aos="fade-left" data-aos-anchor="[data-aos-id-target]">
                                        Required, Not Optional
                                    </div>
                                    <div className="mt-6" data-aos="fade-left" data-aos-delay="200" data-aos-anchor="[data-aos-id-target]">
                                        <h4 className="h4 mb-2">
                                            <span className="text-purple-600">.</span> 어떠한 <span className="text-blue-600 font-bold">키워드도</span> 가능합니다.
                                        </h4>
                                        <p className="text-lg text-gray-400">배포형부터 건바이건 월보장까지 다양한 서비스 제공으로 상위 노출을 보장합니다. 파트너사가 요구하는 키워드를 문제없이 진행하며 최상의 결과를 제공드립니다. <span className="text-purple-600"></span></p>
                                    </div>
                                    <div className="mt-6" data-aos="fade-left" data-aos-delay="400" data-aos-anchor="[data-aos-id-target]">
                                        <h4 className="h4 mb-2">
                                            <span className="text-teal-500">.</span> 어떠한 <span className="text-blue-600 font-bold">SNS도</span> 가능합니다.
                                        </h4>
                                        <p className="text-lg text-gray-400">페이스북, 인스타그램, 유튜브등 소셜미디어의 합리적인 운영방법부터 피드 디자인, 콘텐츠 제작 과정을 포함하여 효과적으로 운영할 수 있도록 모든 정보를 제공드립니다.</p>
                                    </div>
                                    <div className="mt-6" data-aos="fade-left" data-aos-delay="600" data-aos-anchor="[data-aos-id-target]">
                                        <h4 className="h4 mb-2">
                                            <span className="text-pink-500">.</span> 어떠한 <span className="text-blue-600 font-bold">프로젝트도</span> 가능합니다.
                                        </h4>
                                        <p className="text-lg text-gray-400">기획부터 UI/UX디자인 개발까지 IT프로젝트라면 불가능한 것은 없습니다. 뛰어난 PM, 디자이너, 개발자와 함께 세상에 하나뿐인 새로운 프로젝트를 시작해보세요.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Target;
