import React from "react";

function EmailUs() {
    return (
        <section className="relative">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 relative ">
                <div className="pt-32 pb-12 md:pt-40 md:pb-20 border-t border-gray-800">
                    {/* Page header */}
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                        <h1 className="h1 mb-4" data-aos="fade-up">
                            ANY QUESTION?
                        </h1>
                        <p className="text-xl text-gray-400" data-aos="fade-up" data-aos-delay="200">
                            모든 상담내용은 비밀로 유지됩니다. 궁금한게 있으면 언제든 말씀해주세요. 작성해주신 내용은 담당자에게 전달되어 적어주신 유선전화로 빠른 시일 내에 회신드리겠습니다.
                        </p>
                        <p className="text-xl text-blue-400" data-aos="fade-up" data-aos-delay="200">
                            전화문의 : 010-9841-7375
                        </p>
                    </div>

                    {/* Contact form */}
                    <form className="max-w-xl mx-auto">
                        <div className="flex flex-wrap -mx-3 mb-4">
                            <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                                <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="first-name">
                                    성함 <span className="text-red-600">*</span>
                                </label>
                                <input id="first-name" type="text" className="form-input w-full text-gray-300 border-red-500 focus:border-red-500" placeholder="성함을 입력해주세요" required />
                                <p className="text-red-500 text-sm mt-2">This field is required</p>
                            </div>
                            <div className="w-full md:w-1/2 px-3">
                                <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="last-name">
                                    회사명 <span className="text-red-600">*</span>
                                </label>
                                <input id="last-name" type="text" className="form-input w-full text-gray-300" placeholder="회사명과 직책을 입력해주세요" required />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-4">
                            <div className="w-full px-3">
                                <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="email">
                                    이메일 <span className="text-red-600">*</span>
                                </label>
                                <input id="email" type="email" className="form-input w-full text-gray-300" placeholder="이메일을 입력해주세요" required />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-4">
                            <div className="w-full px-3">
                                <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="subject">
                                    연락처 <span className="text-red-600">*</span>
                                </label>
                                <input id="subject" type="text" className="form-input w-full text-gray-300" placeholder="연락처를 입력해주세요" required />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-4">
                            <div className="w-full px-3">
                                <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="country">
                                    문의 유형
                                </label>
                                <select id="country" className="form-select w-full text-gray-300">
                                    <option>블로그 마케팅</option>
                                    <option>모바일 마케팅</option>
                                    <option>인플루언서 마케팅</option>
                                    <option>SNS 마케팅</option>
                                    <option>앱/웹 개발</option>
                                    <option>프로젝트 컨설팅</option>
                                    <option>기타</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-4">
                            <div className="w-full px-3">
                                <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="message">
                                    문의내용
                                </label>
                                <textarea id="message" rows="4" className="form-textarea w-full text-gray-300" placeholder="문의내용을 입력해주세요"></textarea>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-4">
                            <div className="w-full px-3">
                                <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox" />
                                    <span className="text-gray-400 ml-2">제3자 개인정보 제공에 동의합니다.</span>
                                </label>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mt-6">
                            <div className="w-full px-3">
                                <button className="btn text-white bg-purple-600 hover:bg-purple-700 w-full" onClick = {()=> {alert("제출되었습니다");}} >
                                    보내기
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}
export default EmailUs;
