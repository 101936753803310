import React from "react";

function Stats() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 border-t border-gray-800">
        <div className="pb-12 md:pb-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16 pt-20">
            <h1 className="h2 mb-4">OUR ASSETS</h1>
            <p className="text-xl text-gray-400">
              E&S MARKETING의 독보적인 자산을 숫자로 확인해보세요.
            </p>
          </div>

          <div className="grid md:grid-cols-3 bg-gray-800 divide-y md:divide-y-0 md:divide-x divide-gray-700 px-6 md:px-0 md:py-8 text-center">
            {/* 1st item */}
            <div className="py-6 md:py-0 md:px-8">
              <div
                className="text-4xl font-bold leading-tight tracking-tighter text-purple-600 mb-2"
                data-aos="fade-up"
              >
                3,000 <span className="text-2xl">↑</span>
              </div>
              <div
                className="text-lg text-gray-400"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                다양한 분야의 실력있는 프리랜서 마케터
              </div>
            </div>
            {/* 2nd item */}
            <div className="py-6 md:py-0 md:px-8">
              <div
                className="text-4xl font-bold leading-tight tracking-tighter text-purple-600 mb-2"
                data-aos="fade-up"
              >
                95% <span className="text-2xl">↑</span>
              </div>
              <div
                className="text-lg text-gray-400"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                함께한 파트너사의 압도적인 재계약 비율
              </div>
            </div>
            {/* 3rd item */}
            <div className="py-6 md:py-0 md:px-8">
              <div
                className="text-4xl font-bold leading-tight tracking-tighter text-purple-600 mb-2"
                data-aos="fade-up"
              >
                10K <span className="text-2xl">↑</span>
              </div>
              <div
                className="text-lg text-gray-400"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                상위노출 키워드
              </div>
            </div>
          </div>
          <div className="pt-4">
            <div className="text-xs text-teal-400 text-right">
              2022년 02월 기준
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Stats;
