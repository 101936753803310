import React, { useState, useRef, useEffect } from "react";
import Transition from "../utils/Transition.js";

function Tabs() {
    const [tab, setTab] = useState(1);

    const tabs = useRef(null);

    const heightFix = () => {
        if (tabs.current.children[tab]) {
            tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + "px";
        }
    };

    useEffect(() => {
        return () => {
            heightFix();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);

    return (
        <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="py-12 md:py-20 border-t border-gray-800">
                    {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center pb-12" data-aos-id-tabs>
                        <h2 className="h2 mb-4" data-aos="fade-up" data-aos-anchor="[data-aos-id-tabs]">
                            OUR SERVICES
                        </h2>
                        <p className="text-xl text-gray-400" data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-tabs]">
                            E&S마케팅은 블로그 마케팅, SNS 마케팅, 인플루언서 마케팅을 포함한
                        </p>
                        <p className="text-xl text-gray-400" data-aos="fade-up" sdata-aos-delay="200" data-aos-anchor="[data-aos-id-tabs]">
                             프로젝트의 기획, 디자인, 개발 서비스를 제공하고 있습니다.
                        </p>
                    </div>

                    {/* Section content */}
                    <div>
                        {/* Tabs buttons */}
                        <div className="flex flex-wrap justify-center -m-2" data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-tabs]">
                            <button className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${tab !== 1 && "opacity-50"}`} onClick={() => setTab(1)}>
                                <svg className="w-4 h-4 fill-current text-purple-600 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.5 5.5c-.311.001-.62.061-.909.177l-2.268-2.268c.116-.29.176-.598.177-.909a2.5 2.5 0 00-5 0c.001.311.061.62.177.909L3.409 5.677A2.473 2.473 0 002.5 5.5a2.5 2.5 0 000 5c.311-.001.62-.061.909-.177l2.268 2.268c-.116.29-.176.598-.177.909a2.5 2.5 0 105 0 2.473 2.473 0 00-.177-.909l2.268-2.268c.29.116.598.176.909.177a2.5 2.5 0 100-5zM8 11c-.311.001-.62.061-.909.177L4.823 8.909a2.423 2.423 0 000-1.818l2.268-2.268a2.423 2.423 0 001.818 0l2.268 2.268a2.423 2.423 0 000 1.818l-2.268 2.268A2.473 2.473 0 008 11z" />
                                </svg>
                                <span className="text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out">블로그 마케팅</span>
                            </button>
                            <button className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${tab !== 2 && "opacity-50"}`} onClick={() => setTab(2)}>
                                <svg className="w-4 h-4 fill-current text-purple-600 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.043 6.293S9.79 1.905 6.745 0A5.37 5.37 0 014.72 3.887C3.42 5.03.974 7.6 1 10.34A6.285 6.285 0 004.451 16a3.984 3.984 0 011.394-2.755 3.253 3.253 0 001.246-2.185 5.856 5.856 0 013.1 4.881v.013a5.883 5.883 0 003.428-5.106c.216-2.574-1.194-6.074-2.445-7.218a6.793 6.793 0 01-2.13 2.663z" />
                                </svg>
                                <span className="text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out">모바일 마케팅</span>
                            </button>
                            <button className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${tab !== 3 && "opacity-50"}`} onClick={() => setTab(3)}>
                                <svg className="w-4 h-4 fill-current text-purple-600 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 10c-1.1 0-2-.9-2-2 0-.2 0-.3.1-.5L3.6 5 5 3.6l2.5 2.5c.2-.1.3-.1.5-.1 1.1 0 2 .9 2 2s-.9 2-2 2z" />
                                </svg>
                                <span className="text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out">인플루언서 마케팅</span>
                            </button>
                            <button className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${tab !== 4 && "opacity-50"}`} onClick={() => setTab(4)}>
                                <svg className="w-4 h-4 fill-current text-purple-600 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 9v6a8 8 0 008-8V1a8 8 0 00-8 8zM0 6v3a6 6 0 006 6v-3a6 6 0 00-6-6z" />
                                </svg>
                                <span className="text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out">SNS 마케팅</span>
                            </button>
                            <button className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${tab !== 5 && "opacity-50"}`} onClick={() => setTab(5)}>
                                <svg className="w-4 h-4 fill-current text-purple-600 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.936 6.007H8.358l1.594-4.685c.3-.997-.897-1.794-1.694-.997L.284 8.3c-.598.598-.199 1.694.698 1.694H7.56l-1.594 4.685c-.3.997.897 1.794 1.694.997L15.633 7.7c.598-.598.2-1.694-.697-1.694z" />
                                </svg>
                                <span className="text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out">웹/앱 개발</span>
                            </button>
                            <button className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${tab !== 6 && "opacity-50"}`} onClick={() => setTab(6)}>
                                <svg className="w-4 h-4 fill-current text-purple-600 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.043 6.293S9.79 1.905 6.745 0A5.37 5.37 0 014.72 3.887C3.42 5.03.974 7.6 1 10.34A6.285 6.285 0 004.451 16a3.984 3.984 0 011.394-2.755 3.253 3.253 0 001.246-2.185 5.856 5.856 0 013.1 4.881v.013a5.883 5.883 0 003.428-5.106c.216-2.574-1.194-6.074-2.445-7.218a6.793 6.793 0 01-2.13 2.663z" />
                                </svg>
                                <span className="text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out">프로젝트 컨설팅</span>
                            </button>
                        </div>

                        {/* Tabs items */}
                        <div className="relative flex flex-col mt-16" data-aos="fade-up" ref={tabs}>
                            {/* Item 1 */}
                            <Transition show={tab === 1} appear={true} className="w-full" enter="transition ease-in-out duration-500 transform order-first" enterStart="opacity-0 scale-98" enterEnd="opacity-100 scale-100" leave="transition ease-out duration-300 transform absolute" leaveStart="opacity-100 scale-100" leaveEnd="opacity-0 scale-98">
                                <article className="relative max-w-md mx-auto md:max-w-none">
                                    <figure className="md:absolute md:inset-y-0 md:right-0 md:w-1/2">
                                        <img className="w-full h-full object-cover" src={require("../images/tab-01.jpg")} width="516" height="387" alt="Tabs 01" />
                                    </figure>
                                    <div className="relative bg-gray-800 py-8 md:py-16 px-6 md:pr-16 md:max-w-lg lg:max-w-xl">
                                        <h4 className="h4 mb-2">블로그 마케팅</h4>
                                        <p className="text-lg text-gray-400">정보전달을 바탕으로 하는 블로그는 현제 제 2의 홈페이지라 불리고 있습니다. 블로그 마케팅은 체험수기나 일상적인 형식으로 신뢰도가 바탕이 되는 기법으로 브랜드 효과를 높일 수 있고 다양한 분야에 맞는 키워드를 통해 제품이나 서비스를 홍보할 수 있습니다.</p>

                                        {/* <div className="btn-sm text-white bg-purple-600 hover:bg-purple-700 ml-3">
                                            <a onClick = {()=> {window.scrollTo({top:5800, left:0, behavior:'smooth'}); }}>Learn more</a>
                                        </div> */}
                                        <a className="btn-sm text-white bg-purple-600 hover:bg-purple-700 mt-6" onClick = {()=> {window.scrollTo({top:5800, left:0, behavior:'smooth'}); }}>
                                            <span className="text-sm">
                                                Learn more
                                            </span>
                                                                                                                    
                                            <svg className="w-3 h-3 fill-current text-purple-400 flex-shrink-0 ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 5H0v2h6v4l6-5-6-5z" />
                                            </svg>
                                        </a>
                                    </div>
                                </article>
                            </Transition>

                            {/* Item 2 */}
                            <Transition show={tab === 2} appear={true} className="w-full" enter="transition ease-in-out duration-500 transform order-first" enterStart="opacity-0 scale-98" enterEnd="opacity-100 scale-100" leave="transition ease-out duration-300 transform absolute" leaveStart="opacity-100 scale-100" leaveEnd="opacity-0 scale-98">
                                <article className="relative max-w-md mx-auto md:max-w-none">
                                    <figure className="md:absolute md:inset-y-0 md:left-0 md:w-1/2">
                                        <img className="w-full h-full object-cover" src={require("../images/tab-02.jpg")} width="516" height="387" alt="Tabs 02" />
                                    </figure>
                                    <div className="relative bg-gray-800 py-8 md:py-16 px-6 md:pl-16 md:max-w-lg lg:max-w-xl md:ml-auto">
                                        <h4 className="h4 mb-2">모바일 마케팅</h4>
                                        <p className="text-lg text-gray-400">스마트폰이 대중화되면서 미디어의 패턴이 PC에서 모바일로 변하는 추세입니다. 언제 어디서나 검색 가능한 스마트한 시대에 맞춰 PC보다 높은 검색조회수가 바탕이 되기 때문에 주목받고있는 보편적인 마케팅입니다.</p>
                                        <a className="btn-sm text-white bg-purple-600 hover:bg-purple-700 mt-6" onClick = {()=> {window.scrollTo({top:5800, left:0, behavior:'smooth'}); }} >
                                            <span className="text-sm">Learn more</span>
                                            <svg className="w-3 h-3 fill-current text-purple-400 flex-shrink-0 ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 5H0v2h6v4l6-5-6-5z" />
                                            </svg>
                                        </a>
                                    </div>
                                </article>
                            </Transition>


                            {/* Item 3 */}
                            <Transition show={tab === 3} appear={true} className="w-full" enter="transition ease-in-out duration-500 transform order-first" enterStart="opacity-0 scale-98" enterEnd="opacity-100 scale-100" leave="transition ease-out duration-300 transform absolute" leaveStart="opacity-100 scale-100" leaveEnd="opacity-0 scale-98">
                                <article className="relative max-w-md mx-auto md:max-w-none">
                                    <figure className="md:absolute md:inset-y-0 md:right-0 md:w-1/2">
                                        <img className="w-full h-full object-cover" src={require("../images/tab-03.jpg")} width="516" height="387" alt="Tabs 01" />
                                    </figure>
                                    <div className="relative bg-gray-800 py-8 md:py-16 px-6 md:pr-16 md:max-w-lg lg:max-w-xl">
                                        <h4 className="h4 mb-2">인플루언서 마케팅</h4>
                                        <p className="text-lg text-gray-400">페이스북, 인스타그램, 유튜브 등 소셜미디어 네트워크 서비스안에서 젊은 세대를과 적극적으로 소통하는 인플루언서들의 파급력은 높아지고 있습니다. 트렌드를 추구하는 시대에 기본이 되며 젊은 세대들을 타겟팅하기 위해 인지도 및 브랜드를 구축할 수 있습니다.</p>
                                        <a className="btn-sm text-white bg-purple-600 hover:bg-purple-700 mt-6"  onClick = {()=> {window.scrollTo({top:5800, left:0, behavior:'smooth'}); }} >
                                            <span className="text-sm">Learn more</span>
                                            <svg className="w-3 h-3 fill-current text-purple-400 flex-shrink-0 ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 5H0v2h6v4l6-5-6-5z" />
                                            </svg>
                                        </a>
                                    </div>
                                </article>
                            </Transition>

                            {/* Item 4 */}
                            <Transition show={tab === 4} appear={true} className="w-full" enter="transition ease-in-out duration-500 transform order-first" enterStart="opacity-0 scale-98" enterEnd="opacity-100 scale-100" leave="transition ease-out duration-300 transform absolute" leaveStart="opacity-100 scale-100" leaveEnd="opacity-0 scale-98">
                                <article className="relative max-w-md mx-auto md:max-w-none">
                                    <figure className="md:absolute md:inset-y-0 md:left-0 md:w-1/2">
                                        <img className="w-full h-full object-cover" src={require("../images/tab-04.jpg")} width="516" height="387" alt="Tabs 02" />
                                    </figure>
                                    <div className="relative bg-gray-800 py-8 md:py-16 px-6 md:pl-16 md:max-w-lg lg:max-w-xl md:ml-auto">
                                        <h4 className="h4 mb-2">SNS 마케팅</h4>
                                        <p className="text-lg text-gray-400">트렌드를 추구하는 시대에 기본이 되며 젊은 세대들을 타켓팅하여 홍보함으로써 파급력이 높은 마케팅입니다. 페이스북, 인스타그램, 유투브 등 10~20대가 가장 선호하는 소셜미디어 네트워크를 통해 인지도 및 브랜드를 구축할 수 있습니다.</p>
                                        <a className="btn-sm text-white bg-purple-600 hover:bg-purple-700 mt-6"  onClick = {()=> {window.scrollTo({top:5800, left:0, behavior:'smooth'}); }} >
                                            <span className="text-sm">Learn more</span>
                                            <svg className="w-3 h-3 fill-current text-purple-400 flex-shrink-0 ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 5H0v2h6v4l6-5-6-5z" />
                                            </svg>
                                        </a>
                                    </div>
                                </article>
                            </Transition>

                            {/* Item 5 */}
                            <Transition show={tab === 5} appear={true} className="w-full" enter="transition ease-in-out duration-500 transform order-first" enterStart="opacity-0 scale-98" enterEnd="opacity-100 scale-100" leave="transition ease-out duration-300 transform absolute" leaveStart="opacity-100 scale-100" leaveEnd="opacity-0 scale-98">
                                <article className="relative max-w-md mx-auto md:max-w-none">
                                    <figure className="md:absolute md:inset-y-0 md:right-0 md:w-1/2">
                                        <img className="w-full h-full object-cover" src={require("../images/tab-05.jpg")} width="516" height="387" alt="Tabs 01" />
                                    </figure>
                                    <div className="relative bg-gray-800 py-8 md:py-16 px-6 md:pr-16 md:max-w-lg lg:max-w-xl">
                                        <h4 className="h4 mb-2">WEB/APP 개발</h4>
                                        <p className="text-lg text-gray-400">이시대 필수라 불리는 앱! 웹앱, 하이브리드앱, 네이티브앱중 가장 적합한 앱을 전문 담당자가 배정되어 무료 컨설팅이 가능합니다. 가장 합리적인 견적을 받아보세요. 플러터, 리액트네이티브와 같은 최신 크로스플랫폼 기술 사용이 가능합니다.</p>
                                        <a className="btn-sm text-white bg-purple-600 hover:bg-purple-700 mt-6"  onClick = {()=> {window.scrollTo({top:5800, left:0, behavior:'smooth'}); }} >
                                            <span className="text-sm">Learn more</span>
                                            <svg className="w-3 h-3 fill-current text-purple-400 flex-shrink-0 ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 5H0v2h6v4l6-5-6-5z" />
                                            </svg>
                                        </a>
                                    </div>
                                </article>
                            </Transition>


                            {/* Item 2-2 */}
                            <Transition show={tab === 6} appear={true} className="w-full" enter="transition ease-in-out duration-500 transform order-first" enterStart="opacity-0 scale-98" enterEnd="opacity-100 scale-100" leave="transition ease-out duration-300 transform absolute" leaveStart="opacity-100 scale-100" leaveEnd="opacity-0 scale-98">
                                <article className="relative max-w-md mx-auto md:max-w-none">
                                    <figure className="md:absolute md:inset-y-0 md:left-0 md:w-1/2">
                                        <img className="w-full h-full object-cover" src={require("../images/tab-06.jpg")} width="516" height="387" alt="Tabs 02" />
                                    </figure>
                                    <div className="relative bg-gray-800 py-8 md:py-16 px-6 md:pl-16 md:max-w-lg lg:max-w-xl md:ml-auto">
                                        <h4 className="h4 mb-2">커스터마이징 & 컨설팅</h4>
                                        <p className="text-lg text-gray-400">MANAGEMENT, INTERACTION, DEVELOPMENT 이질적인 요소의 의미있는 결합으로 새로운 접근과 탈프레임을 지향합니다. 브랜드를 살아 숨쉬게 하는 크리에이티브 아이디어를 제공하고, 브랜드의 문제를 진단하고 명쾌한 솔루션을 제공합니다.</p>
                                        <a className="btn-sm text-white bg-purple-600 hover:bg-purple-700 mt-6"  onClick = {()=> {window.scrollTo({top:5800, left:0, behavior:'smooth'}); }} >
                                            <span className="text-sm">Learn more</span>
                                            <svg className="w-3 h-3 fill-current text-purple-400 flex-shrink-0 ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 5H0v2h6v4l6-5-6-5z" />
                                            </svg>
                                        </a>
                                    </div>
                                </article>
                            </Transition>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Tabs;
