import React from "react";

import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
import HeroAbout from "../partials/HeroAbout";
import Timeline from "../partials/Timeline";
import HeroHome from "../partials/HeroHome";
import FeaturesHome from "../partials/FeaturesHome";
import Tabs from "../partials/Tabs";
import Target from "../partials/Target";
import Stats from "../partials/Stats";
import FeaturesZigzag from "../partials/FeaturesZigzag";
import Process from "../partials/Process";
import News from "../partials/News";
import Newsletter from "../partials/Newsletter";
import Footer from "../partials/Footer";
import EmailUs from "../partials/EmailUs";
import Location from "../partials/Location";
import BlogList from "../partials/BlogList";
import PricingTables from "../partials/PricingTables";
import Faqs from "../partials/Faqs";
import Testimonials from "../partials/Testimonials";
import Cta from "../partials/Cta";
import KakaoBanner from "../partials/KakaoBanner";
function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />
      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>
        {/*  Page sections */}
        <KakaoBanner />
        <HeroAbout />
        <Timeline />
        <Tabs />
        <Target />
        <Stats />
        {/* <FeaturesZigzag /> */}
        <Process />
        {/* <PricingTables /> */}
        {/* <Testimonials /> */}
        {/* <Cta /> */}
        <Location />
        <EmailUs />
        {/* <HeroHome /> */}
        {/* <FeaturesHome /> */}
        {/* <News /> */}
        <BlogList />
        {/* <Faqs /> */}
        <div id="contact">
          <Newsletter />
        </div>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Home;
