import React from "react";

function Timeline() {
    return (
        <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="py-12 md:py-20">
                    {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                        <h2 className="h2 mb-4">ABOUT US</h2>
                        <p className="text-xl text-gray-400">빠르게 변화하는 마케팅 시장속에서 정점이 되기 위해 E&S마케팅 은 창의력 있는 젊은 인재들과 끊임없이 노력하며 성장 할 것 입니다. E&S마케팅과 협력하여 도약할 수 있는 분 들이라면 언제든지 환영입니다.
                        </p>
                        <p className="text-xl text-gray-400 mt-8">
                            <span className="text-gray-400 font-bold">기획부터 보고서까지 아무것도 모르셔도 됩니다.</span>
                        </p>
                        <p className="text-xl text-gray-400">
                            <span className="text-blue-400 font-bold"> 저희 E&S가 책임지겠습니다</span>
                        </p>
                    </div>

                    {/* Items */}
                    <div className="max-w-3xl mx-auto -my-4 md:-my-6" data-aos-id-timeline>
                        {/* 1st item */}
                        <div className="relative py-4 md:py-6 pl-24" data-aos="fade-up" data-aos-anchor="[data-aos-id-timeline]">
                            <div className="pl-2">
                                <div className="font-architects-daughter text-xl text-purple-600 mb-2">Real</div>
                                <div className="flex items-center mb-3">
                                    <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-green-600 bg-green-200 rounded-full">Tip 1</div>
                                    <div className="absolute left-0 h-full px-px bg-gray-800 ml-20 self-start transform -translate-x-1/2 translate-y-3" aria-hidden="true"></div>
                                    <div className="absolute left-0 w-2 h-2 bg-purple-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2" aria-hidden="true"></div>
                                    <h4 className="h4">TRUST</h4>
                                </div>
                                <p className="text-lg text-gray-400">신뢰를 최우선으로 하는 E&S 마케팅, E&S 마케팅의 파트너사와 고객사가 되어 직접 경험해보세요.</p>
                            </div>
                        </div>

                        {/* 2nd item */}
                        <div className="relative py-4 md:py-6 pl-24" data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-timeline]">
                            <div className="pl-2">
                                <div className="font-architects-daughter text-xl text-purple-600 mb-2">New features</div>
                                <div className="flex items-center mb-3">
                                    <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-green-600 bg-green-200 rounded-full">Tip 2</div>
                                    <div className="absolute left-0 h-full px-px bg-gray-800 ml-20 self-start transform -translate-x-1/2 translate-y-3" aria-hidden="true"></div>
                                    <div className="absolute left-0 w-2 h-2 bg-purple-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2" aria-hidden="true"></div>
                                    <h4 className="h4">CUSTOMIZE</h4>
                                </div>
                                <p className="text-lg text-gray-400">E&S마케팅이 진행하는 프로젝트는 어떠한 환경에서도 최적화되어 있으므로 유동적으로 커스터마이징이 가능합니다.</p>
                            </div>
                        </div>

                        {/* 3rd item */}
                        <div className="relative py-4 md:py-6 pl-24" data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-timeline]">
                            <div className="pl-2">
                                <div className="font-architects-daughter text-xl text-purple-600 mb-2">Highly</div>
                                <div className="flex items-center mb-3">
                                    <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-green-600 bg-green-200 rounded-full">Tip 3</div>
                                    <div className="absolute left-0 h-full px-px bg-gray-800 ml-20 self-start transform -translate-x-1/2 translate-y-3" aria-hidden="true"></div>
                                    <div className="absolute left-0 w-2 h-2 bg-purple-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2" aria-hidden="true"></div>
                                    <h4 className="h4">PROFESSIONAL</h4>
                                </div>
                                <p className="text-lg text-gray-400">오랜 경험을 바탕으로 성공적인 마케팅의 발판이 되어드리겠습니다. 복잡한 부분은 전문가에게 맡겨주세요. 걱정은 덜고 완벽한 휴식을 취하실 수 있습니다.</p>
                            </div>
                        </div>

                        {/* 4th item */}
                        <div className="relative py-4 md:py-6 pl-24" data-aos="fade-up" data-aos-delay="600" data-aos-anchor="[data-aos-id-timeline]">
                            <div className="pl-2">
                                <div className="font-architects-daughter text-xl text-purple-600 mb-2">Past Or</div>
                                <div className="flex items-center mb-3">
                                    <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-green-600 bg-green-200 rounded-full">Tip 4</div>
                                    <div className="absolute left-0 w-2 h-2 bg-purple-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2" aria-hidden="true"></div>
                                    <h4 className="h4">MODERN</h4>
                                </div>
                                <p className="text-lg text-gray-400">빠르게 변화하는 민감한 환경속에서 발빠르게 대처하여 파트너사와 고객사 모두의 공동 우승 목표와 상호 가치를 창출합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Timeline;
