import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropdown from "../utils/Dropdown";
import ScrollBox from "./ScrollBox";

function KakaoBanner() {
  return (
    <div
      // className="fixed r-20 b-20"
      style={{
        position: "fixed",
        "z-index": "999",
        right: "30px",
        bottom: "30px",
      }}
    >
      <a href="http://pf.kakao.com/_dyxjxls/chat" target="_blank">
        <img
          src={require("../images/Button_KakaoTalkChat_88.png")}
          width="50"
        ></img>
      </a>
    </div>
  );
}

export default KakaoBanner;
