import React from "react";

import useTypingEffect from "use-typing-effect";

function HeroAbout() {
    const typingEffect = useTypingEffect(["신뢰있는 바이럴 마케팅", "독보적인 솔루션을 제공합니다", "이제는 선택이 아닌 필수입니다"], {
        loop: true,
    });
    return (
        <section className="relative">
            {/* Background image */}
            {/* <div className="absolute inset-0"> */}
            <div className="absolute inset-0">
                {/* <img className="w-full h-full object-cover" src={require("../images/about-hero.jpg")} width="1440" height="600" alt="About" /> */}
                <img className="w-full h-full object-cover" src={require("../images/main-01.jpg")} width="100%" height="100%" alt="About" />
                <div className="absolute inset-0 bg-gray-900 opacity-75" aria-hidden="true"></div>
            </div>

            {/* Hero content */}
            <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
                <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                    <div className="max-w-3xl mx-auto text-center">
                        <h1 className="h1 mb-4" data-aos="fade-up">
                            E&S MARKETING
                        </h1>
                        <div className="h-32">
                            <h1 className="h1 mb-4" data-aos="fade-up">
                                <span className="text-blue-400 h-32">{typingEffect}</span>
                            </h1>
                        </div>
                        <p className="text-xl text-gray-400 mb-8" data-aos="fade-up" data-aos-delay="200">
                            파트너사의 든든한 지원군이 되어 신뢰있는 마케팅을 책임지겠습니다.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HeroAbout;
