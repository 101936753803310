import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="py-12 md:py-16">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          {/* Top area: Blocks */}
          <div className="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">
            {/* 1st block */}
            <div className="md:col-span-4 lg:col-span-5">
              <div className="mb-2">
                {/* Logo */}
                <Link to="/" className="inline-block" aria-label="Cruip">
                  <img
                    src={require("../images/eands_logo.png")}
                    width="200"
                    alt="Hero"
                  ></img>
                </Link>
              </div>
              <div className="text-gray-400">
                이앤에스마케팅은 고객사와 파트너사의 든든한 지원군이 되어
                신뢰있는 마케팅을 책임지겠습니다.
              </div>
              <div className="mt-5">
                |
                <a
                  href="https://blog.naver.com/ensmarketing"
                  className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out  ml-3 mr-3"
                  target="_blank"
                >
                  네이버 공식블로그
                </a>
                |
                <a
                  href="https://www.instagram.com/ensmarketing/"
                  className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out ml-3 mr-3"
                  target="_blank"
                >
                  인스타그램
                </a>
                |
                <a
                  href="http://pf.kakao.com/_dyxjxls/chat/"
                  className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out ml-3 mr-3"
                  target="_blank"
                >
                  카카오톡
                </a>
                |
              </div>
            </div>

            {/* 2nd, 3rd and 4th blocks */}
            <div className="md:col-span-8 lg:col-span-7 grid sm:grid-cols-2 gap-8">
              {/* 2nd block */}
              <div className="text-sm">
                <h6 className="text-gray-200 font-medium mb-1"> </h6>
                <ul></ul>
              </div>

              {/* 4th block */}
              <div className="text-sm text-right">
                <h6 className="text-gray-200 font-medium mb-1">
                  (주)이앤에스마케팅
                </h6>
                <ul>
                  <li className="mb-1">
                    Business Registration{" "}
                    <Link
                      to="#"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      378-87-01204
                    </Link>
                  </li>
                  <li className="mb-1">
                    Address{" "}
                    <Link
                      to="#"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      경기도 용인시 기흥구 죽전로 7 801호
                    </Link>
                  </li>
                  <li className="mb-1">
                    E-mail{" "}
                    <Link
                      to="#"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      qna@eandsmarketing.com
                    </Link>
                  </li>
                  <li className="mb-1">
                    Phone{" "}
                    <Link
                      to="#"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      (+82)10-9841-7375
                    </Link>
                  </li>
                  <li className="mb-1">
                    Available at{" "}
                    <Link
                      to="#"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Mon - Fri / 9:00 ~ 18:00
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Bottom area */}
          <div className="md:flex md:items-center md:justify-between">
            {/* Copyrights note */}
            <div className="text-gray-400 text-sm mr-4">
              &copy; 2022 E&S M. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
