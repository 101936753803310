import React from 'react';
import { Link } from 'react-router-dom';

function BlogList() {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">

          {/*  Page header */}
          <div className="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
            <h1 className="h1" data-aos="fade-up">E&S에서 인재를 채용합니다.</h1>
            </div>

          {/*  Featured article */}
          <div className="pb-12 md:pb-20">
            <article className="max-w-sm mx-auto md:max-w-none grid md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 xl:gap-16 items-center">
              <Link to="blog-post" className="relative block group" data-aos="fade-right" data-aos-delay="200">
                <div className="absolute inset-0 bg-gray-800 hidden md:block transform md:translate-y-2 md:translate-x-4 xl:translate-y-4 xl:translate-x-8 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out pointer-events-none" aria-hidden="true"></div>
                <figure className="relative h-0 pb-9/16 md:pb-3/4 lg:pb-9/16 overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out">
                  <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/blog-post-02.jpg')} width="540" height="303" alt="Blog post 01" />
                </figure>
              </Link>

              <div data-aos="fade-left" data-aos-delay="200">
                <header>
                  <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <span to="#" className="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-purple-600 hover:bg-purple-700 transition duration-150 ease-in-out">Marketer</span>
                      </li>
                      <li className="m-1">
                        <span to="#" className="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-blue-500 hover:bg-blue-600 transition duration-150 ease-in-out">Planner</span>
                      </li>
                    </ul>
                  </div>
                  <h3 className="h3 text-2xl lg:text-3xl mb-2">
                    <Link to="/blog-post" className="hover:text-gray-100 transition duration-150 ease-in-out">E&S 마케팅 채용공고</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-400 flex-grow">안녕하세요. 저희 회사와 함께 성장하실 인재를 채용하고 있습니다. 평소에 마케팅에 관심이 많거나 영업에 소질이 있으신 분들은 언제든지 이력서를 제출해 주시기 바랍니...</p>

                {/* <footer className="flex items-center mt-4">
                  <span>
                    <img className="rounded-full flex-shrink-0 mr-4" src={require('../images/news-author-04.jpg')} width="40" height="40" alt="Author 04" />
                  </span>
                  <div>
                    <span to="#" className="font-medium text-gray-200 hover:text-gray-100 transition duration-150 ease-in-out">인사팀 팀장</span>
                    <span className="text-gray-700"> - </span>
                    <span className="text-gray-500">2020.11.10</span>
                  </div>
                </footer> */}

              </div>
            </article>
          </div>

        </div>
      </div>
    </section>
  );
}

export default BlogList;